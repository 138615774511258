<template>
  <div v-if="isTermsData()">
    <div class="inquiry-list-wrapper">
<!--      <div class="list-wrapper">
        <div class="inquiry-day" style="text-align: right;">{{ $t('reg_date') }}: {{ returnDate(termsData.created_at) }}</div>
      </div>-->
      <div class="inquiry-content">
        <span class="ql-editor" v-html="replaceBr(termsData.c_config)"></span>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/mixins/util";

export default {
  name: "TermsDetailDefaultLayout",
  mixins: [],
  components: {},
  inject: ['setTermsData'],
  provide() {
    return {}
  },
  props: {
    termsData: {
      type: Object,
      default: () => {
        return []
      }
    },
  },
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
    this.setTerms();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    isTermsData() {
      return !util.isEmpty(this.termsData)
    },
    returnDate(data) {
      return this.$moment(data).format('YYYY.MM.DD');
    },
    setTerms() {
        if(util.isEmpty(this.setTermsData) && util.isFunction(this.setTermsData)) {
            this.setTermsData(this.termsData);
        }
    },
    replaceBr(content) {
      if (util.isEmpty(content)) {
        return '';
      }
      // return content.replace(/(\n|\r\n)/g, '<br>');
      return content;
    },

  },
  watch: {
    'termsData.c_config': {
      deep: true,
      handler: function (val, oldVal) {
        this.setTerms();
      }
    }
  },
}
</script>

<style scoped>

</style>
